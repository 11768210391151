<template>
	<BaseLayout>
		<div>
			<Toolbar class="w-full bg-primary p-5" @clickLeft="$router.push('/launch')">
				<template v-slot:start>
					<p class="text-white">
						<Icon class="h-5 w-5" icon="angleLeft" />
					</p>
				</template>
				{{ $t('register.memberRegister') }}
			</Toolbar>
			<div class="flex w-full justify-center bg-primary pb-16 pt-6 iPhone:pb-5 iPhone:pt-2">
				<img src="assets/logo.svg" class="w-52" alt="" loading="lazy" />
			</div>

			<div class="px-5 pt-10">
				<p class="text-md py-2 text-secondary no-tap-highlighting">{{ $t('register.title') }}</p>
				<!-- Register method  -->
				<div class="my-2 flex w-full justify-around text-primary">
					<button class="inline-flex w-36 justify-center rounded-3xl border-2 border-primary py-2 no-tap-highlighting" @click="type = 'PHONE'" :disabled="isLoading" :class="type === 'PHONE' ? 'bg-primary text-white' : ' '">
						<Icon class="h-5 w-5" icon="phone" />
						<p class="px-1 text-sm">{{ $t('register.mobileLogin') }}</p>
					</button>
					<button class="inline-flex w-36 justify-center rounded-3xl border-2 border-primary py-2" @click="type = 'EMAIL'" :disabled="isLoading" :class="type === 'EMAIL' ? 'bg-primary text-white' : ''">
						<Icon class="h-5 w-5" icon="envelope" />
						<p class="px-1 text-sm">{{ $t('register.emailSignup') }}</p>
					</button>
				</div>

				<form class="my-5" @submit.stop.prevent="register">
					<div v-if="type === 'PHONE'">
						<!-- Phone Register -->
						<div class="flex justify-between space-x-2 rounded-xl bg-light-grey p-4">
							<div @click="showAreaCodeModal = true" class="inline-flex items-center space-x-1">
								<div v-if="!areaCode">
									<p class="whitespace-nowrap text-xs">{{ $t('register.countryCode') }}</p>
									<p class="whitespace-nowrap text-xs">{{ $t('register.countryCode2') }}</p>
								</div>
								<div v-if="areaCode">
									<p class="text-md whitespace-nowrap">{{ areaCode }}</p>
								</div>
								<Icon class="h-3 w-3" icon="angleDown" />
							</div>
							<input v-model="phoneNumber" type="tel" class="w-full bg-transparent text-sm placeholder-muted" :placeholder="$t('register.mobileSignup')" required />
							<button id="sign-in-button" class="whitespace-nowrap text-right text-sm text-primary" @click="getVerificationId">{{ $t('register.verifySMS') }}</button>
						</div>

						<div class="my-2 rounded-xl bg-light-grey p-4 text-sm">
							<input v-model="vcode" type="tel" :placeholder="$t('register.verifyCode')" required class="outline-none2 w-full bg-transparent placeholder-muted" />
						</div>
					</div>
					<div v-else-if="type === 'EMAIL'">
						<!-- Email Register -->
						<div class="w-full rounded-xl bg-light-grey p-4 text-sm">
							<input v-model="emailAddress" type="email" :placeholder="$t('register.emailAddress')" required class="outline-none2 w-full bg-transparent placeholder-muted" />
						</div>

						<div class="my-2 rounded-xl bg-light-grey p-4 text-sm">
							<input v-model="password" type="password" :placeholder="$t('register.createPassword')" required class="outline-none2 w-full bg-transparent placeholder-muted" />
						</div>

						<div class="my-2 rounded-xl bg-light-grey p-4 text-sm">
							<input v-model="confirmPassword" type="password" :placeholder="$t('register.reenterPassword')" required class="outline-none2 w-full bg-transparent placeholder-muted" />
						</div>
					</div>
					<Button type="submit" :loading="isLoading" :disabled="isLoading">{{ $t('register.signup') }}</Button>
				</form>

				<!-- Go to Login -->
				<div class="mt-6 text-center text-xs text-muted">
					<p>
						{{ $t('register.alreadyMemeber') }}<span @click="$router.push('/signin')" class="px-2 text-primary underline">{{ $t('register.alreadyMemeberPush') }}</span>
					</p>
				</div>
			</div>

			<!-- Footer -->
			<div class="fixed bottom-0 w-full bg-white bg-opacity-40 p-2 text-center text-secondary pb-safe">
				<p class="mb-2 text-xs">
					{{ $t('register.tNcSignup') }} <span class="text-primary underline" @click="$router.push('/privacyPolicy')">{{ $t('register.tNcSignupPush') }} </span>
				</p>
			</div>
		</div>
	</BaseLayout>
	<Modal v-if="showAreaCodeModal" @close="showAreaCodeModal = false" :title="$t('location.locationSearch')">
		<template v-slot:search>
			<div class="absolute top-20 w-full">
				<Search>
					<input v-model="inputAreaCode" type="text" class="ml-2 w-full bg-transparent text-sm" :placeholder="$t('location.locationSearch')" />
				</Search>
			</div>
		</template>
		<div class="w-screen bg-white pt-16">
			<div class="flex h-full flex-col divide-y overflow-x-scroll">
				<div v-for="country in searchAreaCode" :key="country" class="flex justify-between py-2" :class="country.dial_code == areaCode ? 'bg-primary text-white' : null">
					<label :for="country.dial_code" class="whitespace-nowrap py-1 px-5 text-lg">
						{{
							`${country.name}
							（${country.dial_code}）`
						}}</label
					>
					<input class="w-full appearance-none" v-model="selectAreaCode" :id="country.dial_code" :value="country.dial_code" :checked="country.dial_code == areaCode" type="radio" :name="country" @change=";(areaCode = selectAreaCode), (showAreaCodeModal = false)" />
				</div>
			</div>
		</div>
	</Modal>
	<Toast v-if="error" @close="error = null">{{ error.message }}</Toast>
</template>

<script>
import { storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useSharedStore } from '@/store/shared'
import { useI18n } from 'vue-i18n'
import { useCustomerStore } from '@/store/customer'

import { auth } from '@/firebase'
import { Capacitor } from '@capacitor/core'
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import { FirebaseAuthentication } from '@capacitor-firebase/authentication'

export default {
	setup() {
		const router = useRouter()
		const store = useStore()
		const { locale } = useI18n()
		const { config } = storeToRefs(useSharedStore())
		const { t } = useI18n({ useScope: 'global' })
		const countryLists = config.value.countryCode

		const type = ref('PHONE')
		const areaCode = ref('')
		const phoneNumber = ref('')
		const emailAddress = ref('')
		const password = ref('')
		const confirmPassword = ref('')
		const inputAreaCode = ref('')
		const selectAreaCode = ref('')

		const vid = ref(null)
		const vcode = ref('')

		const showAreaCodeModal = ref(false)

		const error = ref(null)
		const isLoading = computed(() => store.state.loadingState)
		const { registerWithEmail, registerWithPhone } = useCustomerStore()

		async function getVerificationId() {
			if (!areaCode.value || !phoneNumber.value) {
				error.value = { message: t('launch.phoneError') }
				setTimeout(() => {
					error.value = null
				}, 10000)
				return
			}
			store.commit('setLoadingState', { loadingState: true })
			error.value = null
			if (Capacitor.getPlatform() === `web`) {
				auth.useDeviceLanguage()
				const recaptchaVerifier = new RecaptchaVerifier('sign-in-button', { size: 'invisible' }, auth)
				vid.value = await signInWithPhoneNumber(auth, areaCode.value + phoneNumber.value, recaptchaVerifier)
			} else {
				const { verificationId } = await FirebaseAuthentication.signInWithPhoneNumber({ phoneNumber: areaCode.value + phoneNumber.value })
				vid.value = verificationId
			}
			store.commit('setLoadingState', { loadingState: false })
		}

		async function register() {
			try {
				store.commit('setLoadingState', { loadingState: true })
				error.value = null
				if (password.value !== confirmPassword.value) throw { code: 'auth/confirm-password-not-match', message: 'Error (auth/confirm-password-not-match).' }
				if (type.value === 'PHONE') {
					await registerWithPhone(areaCode.value, phoneNumber.value, vid.value, vcode.value)
				} else {
					await registerWithEmail(emailAddress.value, password.value)
				}
				router.push('/account')
				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				console.error(e)
				error.value = e
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}

		const searchAreaCode = computed(() => {
			return countryLists[locale.value].filter(item => {
				const fields = ['name', 'code', 'dial_code']
				let flag = false
				fields.forEach(field => {
					if (item[field].toUpperCase().includes(inputAreaCode.value.toUpperCase())) flag = true
				})
				if (flag) return item
			})
		})

		return {
			type,
			areaCode,
			phoneNumber,
			emailAddress,
			password,
			confirmPassword,
			inputAreaCode,
			vcode,
			error,
			isLoading,
			getVerificationId,
			register,
			countryLists,
			searchAreaCode,
			showAreaCodeModal,
			selectAreaCode,
		}
	},
}
</script>
